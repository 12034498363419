/* eslint-disable import/first */

import React from "react"
import { Router } from "@reach/router"

// Ref: https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/

import { useTheme } from "@starberryComponentsMui"
import {
    MYACCOUNT_UNSUBSCRIBE
} from "@myaccountUrls";

import Unsubscribe from "@myaccount/pages/unsubscribe";
import PageHeaderHelmet from "@components/pageHeader";

import _myAccount from "@myaccountSampleData/myAccount.yaml"

const MyAccountPages = () => {

    const theme = useTheme()

    // In real use, the people in 'persons' will probably come from CMS,
    // while properties come from another data source.
    const myAccount =
        theme.getProp("subthemes.myAccount.sampleData") ?? _myAccount

    return (
        <>
            <PageHeaderHelmet theme={theme} />
            <Router>
                <Unsubscribe path={MYACCOUNT_UNSUBSCRIBE} myAccount={myAccount} theme={theme} />
            </Router>
        </>
    )
}

export default MyAccountPages
